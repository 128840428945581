module.exports = {
  siteTitle: 'Kamal Nayan', // <title>
  manifestName: 'Kamal Nayan',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  authorName:'KAMAL NAYAN',
  heading: '',
  // social
  socialLinks: [
    {
	icon:  'fa-facebook', name:  'facebook',  url:  'https://www.facebook.com/kamal.nayan.969952/'},
{  icon:  'fa-instagram',  name:  'instagram',  url:  'https://www.instagram.com/kamal._.nayan/'},

    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/Kamalnayan77',
    },
    {

      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:kamal987nayan987@gmail.com',
    },
  ],
};
